export default {
  'css': null,
  'exports': null,

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<a expr0="expr0">Pinterest</a> /\n  <a expr1="expr1">LinkedIn</a> /\n  <a expr2="expr2">Instagram</a>',
      [{
        'redundantAttribute': 'expr0',
        'selector': '[expr0]',

        'expressions': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'href',

          'evaluate': function(scope) {
            return ['https://www.pinterest.com/', scope.props.pinterest].join('');
          }
        }]
      }, {
        'redundantAttribute': 'expr1',
        'selector': '[expr1]',

        'expressions': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'href',

          'evaluate': function(scope) {
            return ['https://www.linkedin.com/in/', scope.props.linkedin].join('');
          }
        }]
      }, {
        'redundantAttribute': 'expr2',
        'selector': '[expr2]',

        'expressions': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'href',

          'evaluate': function(scope) {
            return ['https://www.instagram.com/', scope.props.instagram].join('');
          }
        }]
      }]
    );
  },

  'name': 'social'
};